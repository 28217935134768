import NavMenu from "./nav/NavMenu";
import ScrollActive from "./scroll/ScrollActive";
import Utils from "./utils/Utils";
import DataSocialShareClick from "data-social-share-click" ;
import BricksManager from "./bricks/BricksManager";
import BubullesHtml from "./bubulles/BubullesHtml";
import SmoothScrollManager from "./scroll/SmoothScrollManager";
import PageTransition from "./page-transition/PageTranstion";
import LottieLoader from "./lottie/LottieLoader";
import AjaxOnScroll from "./components/AjaxOnScroll";
import Form from "./blocks/form/Form";
window.lottie=require("lottie-web");
window.lottie.setQuality("low");
window.utils=new Utils();

//$("#test").text(JSON.stringify(window.utils.device));

require("./gsap.boot");

export default class Site{
    constructor() {
        /**
         *
         * @type {Site}
         */
        let me = this;

        let smoothScrollManagerEnabled=true;
        if(utils.device.isEdge||utils.device.isMobile||utils.device.isIos||utils.device.isMac){
            smoothScrollManagerEnabled=false;
        }
        window.smoothScrollManager=new SmoothScrollManager(smoothScrollManagerEnabled);
        if(smoothScrollManagerEnabled){
            setInterval(
                function(){
                    if($(".social-wall").length){
                        window.smoothScrollManager.refresh();
                    }
                },3000
            )
        }
        window.scrollActive=new ScrollActive();
        window.bubullesHtml=new BubullesHtml();
        window.navMenu=new NavMenu();
        window.pageTransition=new PageTransition();
        me._initListeners();
        //---------------------go------------------------------------------
        me.onDomChange();
        window.bricksManager=new BricksManager();

    }

    /**
     *
     * @private
     */
    _initListeners() {

        let me=this;
        require("./components/data-zoom-img");
        require("./components/data-is-lang");
        require("./components/data-toggle-active-parent");
        //require("./organisms/data-cards-container.js");
        require("./nav/nav-scroll-up-down");

        let socialShares=new DataSocialShareClick();
        socialShares.listenClicks();

        //gestion de ce qui se passe quand un element est visible ou non
        $body.on("SCROLL_INACTIVE SCROLL_ACTIVE",function(e){

            let setSaveSrc=function($vdo){
                if(!$vdo.attr("save-src")){
                    $vdo.attr("save-src",$vdo.attr("src"));
                }
            };
            let pauseAndKillSrc=function($vdo){
                let vdo=$vdo[0];
                vdo.pause();
                setSaveSrc($vdo);
                $vdo.attr("src",""); //coupe le dwd
            };
            let playVideo=function($vdo){
                setSaveSrc($vdo);
                $vdo.attr("src",$vdo.attr("save-src"));
                let vdo=$vdo[0];
                let autoplay=false;
                switch (true) {
                    case $vdo.attr("autoplay"):
                        autoplay=true;
                        break;
                    case utils.device.isMobile:
                        if($vdo.attr("autoplay-mobile")){
                            autoplay=true;
                        }
                        break;
                    case utils.device.isDesktop:
                        if($vdo.attr("autoplay-desktop")){
                            autoplay=true;
                        }
                        break;
                }
                if(!autoplay){
                    return;
                }

                let playPromise = vdo.play();
                if (playPromise !== undefined) {
                    playPromise.then(_ => {
                        vdo.play();
                    })
                    .catch(error => {
                        if(!$vdo.attr("muted")){
                            vdo.muted=true;
                        }
                        vdo.play();
                    });
                }
                if($vdo.is("[onlyoneplaying]")){
                    $("video[onlyoneplaying]").not($vdo).each(function(){
                        pauseAndKillSrc($(this));
                    })
                }
            };

            let $target=$(e.target)
            if($target.is("video")){
                let $vdo=$(e.target);
                let vdo=$vdo[0];
                if(e.type==="SCROLL_INACTIVE"){
                    pauseAndKillSrc($vdo);
                }else{
                    playVideo($vdo);



                }
            }

            //changement d'url au scroll
            if($target.is("[is-url]")){
                if(e.type==="SCROLL_ACTIVE") {
                    document.title=$target.attr("title");
                    history.replaceState({}, $target.attr("title"), $target.attr("is-url"));
                }
            }
        });

        //quand on change d'url.............
        $body.on(EVENTS.HISTORY_CHANGE_URL,function(){
            pageTransition.cover();
            //stope en attendant que la transition soit finie
            PovHistory.readyToinject=false;
            //dit qu'on est prêt à afficher la page (s'assure qu'on reste au moins une seconde sur l'écran de transition)
            setTimeout(function(){
                PovHistory.readyToinject=true;
            },500);
            navMenu.close();
        });

        //changement d'url et HTML injecté
        $body.on(EVENTS.HISTORY_CHANGE_URL_LOADED_INJECTED,function(){
            me.onDomChange();

            //scroll top
            $(window).scrollTop(0);

            //google analytics
            if(typeof gtag !== 'undefined' && LayoutVars.googleAnalyticsId){
                //hit google analytics
                gtag('config', LayoutVars.googleAnalyticsId, {'page_path': location.pathname});
            }
            //transition
            setTimeout(function(){
                //timeout pour éviter les perfs
                pageTransition.uncover();
            },1000*0.5);

        });

        $body.on(Pov.events.DOM_CHANGE,function(){
            me.onDomChange();
        });



    }

    /**
     * Initialisations d'objets dom
     */
    onDomChange(){
        console.log("onDomChange")
        $body.attr("page-type",PovHistory.currentPageInfo.recordType);
        if(PovHistory.currentPageInfo.recordType==="project"){
            $body.attr("show-footer","0");
        }
        LottieLoader.initFromDom();
        bubullesHtml.fromDom();
        scrollActive.observe();
        smoothScrollManager.initFromDom();
        navMenu.initFromDom();
        Form.fromDom();
        AjaxOnScroll.initFromDom();

    }
}