export default class NavMenu{
    constructor() {
        this.animsActive=true;
        switch (true) {
            case utils.device.isMac:
            case utils.device.isMobile:
                this.animsActive=false;
        }

        this.isFun=true;
        if(utils.device.isIos || utils.device.isEdge || utils.device.isSafari){
            this.isFun=false;
        }

        let me=this;
        $body.on("click","[nav-menu-click='toggle']",function(){
            me.toggle()
        });
        this.anim=lottie.loadAnimation({
            container: $("#nav-content .background").get(0), // the dom element that will contain the animation
            renderer: 'svg',
            rendererSettings: {
                preserveAspectRatio : 'none',
            },
            loop: false,
            autoplay: false,
            path: LayoutVars.fmkHttpRoot+"/project/_src/layout/transition.liquid-blue.json" // the path to the animation json
        });
        this.logo=lottie.loadAnimation({
            container: $("#nav .logo .w-mani").get(0), // the dom element that will contain the animation
            renderer: 'svg',
            rendererSettings: {
                //preserveAspectRatio : "xMidYMin meet"
                preserveAspectRatio : "xMidYMid meet"
            },
            loop: true,
            autoplay: me.animsActive,
            path: LayoutVars.fmkHttpRoot+"/project/_src/nav/logo.lottie.json" // the path to the animation json
        });



        this.bubulles=lottie.loadAnimation({
            container: $("#nav .bubulles").get(0), // the dom element that will contain the animation
            renderer: 'svg',
            rendererSettings: {
                //preserveAspectRatio : "xMidYMin meet"
                preserveAspectRatio : "xMidYMid meet"
            },
            loop: true,
            autoplay: false,
            path: LayoutVars.fmkHttpRoot+"/project/_src/bubulles/lottie/bubulle-home.json" // the path to the animation json
        });






    }

    initFromDom(){
        let $logo=$("#nav .logo");
        let $bubulles=$("#nav .bubulles");
        let $logoPlaceHolder=$(".big-logo-placeholder").not(".init");
        let me=this;
        let onChange=function(entries, observer){
            entries.forEach(entry => {
                let active=entry.isIntersecting;
                if(active){
                    $bubulles.addClass("big");
                    //if(me.animsActive){

                        me.bubulles.play();
                    //}else{
                    //    console.log("stop bubulles")
                    //    me.bubulles.stop();
                    //}
                    gsap.to($logo.find(".w-mani"),0.5,{height:$("#nav-bar").height()*2,y:100,duration:0.5})
                    gsap.to($logo.find(".w-live"),0.5,{height:$("#nav-bar").height()*4,y:60,duration:0.5})

                    console.log($logo);

                }else{
                    gsap.to($logo.find(".w-mani"),0.5,{height:$("#nav-bar").height(),y:0,duration:0.5})
                    gsap.to($logo.find(".w-live"),0.5,{height:100,y:-10,duration:0.5})
                    $bubulles.removeClass("big");
                    me.bubulles.stop();
                }
            });
        };
        if($logoPlaceHolder.length){
            $logoPlaceHolder.addClass("init");
            let observer = new IntersectionObserver(onChange, {
                root: null,
                rootMargin: '100px'
            });
            observer.observe($logoPlaceHolder[0]);
        }
    }

    open(){
        console.log("open")
        $body.addClass("nav-open");
        if(this.isFun){
            this.anim.setDirection(1);
            this.anim.goToAndPlay(1,true);
        }else{
            this.anim.goToAndStop(this.anim.totalFrames,true);
        }


    }
    close(){
        console.log("close")
        $body.removeClass("nav-open");
        if(this.isFun) {
            this.anim.setDirection(-1);
            this.anim.play();
        }else{
            this.anim.goToAndStop(1);
        }

    }
    toggle(){
        if($body.is(".nav-open")){
            this.close();
        }else{
            this.open();
        }
        //$body.toggleClass("nav-open");
    }
}
