$body.on("click","[data-toggle-active]:not(.active)",function(e){
    e.preventDefault();
    e.stopPropagation();
    /*
    let me=$(this);
    let parent=$(me.closest("[data-toggle-active-parent]"));
    let bio=$(parent.find(".wrap-bio"));
    bio.toggle("active");
    */
    let me=$(this);
    let bio=$(me.find(".text-bio"));
    bio.toggle("active");

});