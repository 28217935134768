export default class UtilsDevice {

    constructor() {
        let ua=navigator.userAgent;
        this.ua=ua;
        let uaLower=ua.toLowerCase();
        /**
         * ios or not
         * @type {boolean}
         */
        this.isIos=
            uaLower.indexOf('iphone') > -1 ||
            uaLower.indexOf('ipad') > -1 ||
            (uaLower.indexOf('macintosh') > -1 && this.isTouchDevice()) //un mac tactile, c'est ios ;)
        ;

        /**
         * microsoft edge or not
         * @type {boolean}
         */
        this.isEdge=ua.indexOf("Edge") !== -1;
        /**
         * microsoft internet explorer or not
         * @type {boolean}
         */
        this.isMsie=ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./);

        this.isMobile=this.isIos||screen.width<900||screen.height<900;
        /**
         *
         * @type {boolean}
         */
        this.isDesktop= !this.isMobile;
        /**
         *
         * @type {boolean}
         */
        this.isMac=/macintosh/.test(uaLower);
        /**
         *
         * @type {boolean|boolean}
         */
        this.isSafari= uaLower.indexOf('safari') > -1 && this.isMac;
        /**
         *
         * @type {boolean}
         */
        this.isTouch=this.isTouchDevice();

    }

    /**
     * hover possible or not ?
     * @returns {boolean}
     */
    isHoverCapable(){
        return !window.matchMedia("(hover: none)").matches;
    }

    /**
     * touch possible or not?
     * @returns {boolean}
     */
    isTouchDevice() {
        return ('ontouchstart' in window) || ('msmaxtouchpoints' in window.navigator);
    }


}