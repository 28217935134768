window.lottieColors={
    orange:[
        "bubulle-orange-1",
        "bubulle-orange-2",
        "bubulle-orange-4",
        "bubulle-orange-5",
        "bubulle-orange-7"
    ],
    blue:[
        "bubulle-blue-1",
        "bubulle-blue-2",
        "bubulle-blue-4",
        "bubulle-blue-5",
        "bubulle-blue-7"
    ],
    sunrise:[
        "bubulle-sunrise-1",
        "bubulle-sunrise-2",
        "bubulle-sunrise-4",
        "bubulle-sunrise-5",
        "bubulle-sunrise-7"
    ]
};




