//nav qui suit le scroll
require("./nav-scroll-up-down.less");
STAGE.on(EVENTS.SCROLL_UP,function(){
    $body.attr("scroll-dir","up");
});
STAGE.on(EVENTS.SCROLL_DOWN,function(){
    if(STAGE.scrollY>0){
        $body.attr("scroll-dir","down");
    }else{
        $body.attr("scroll-dir","up");
    }

});